"use client";

import SeriesCard from "@/components/series-card";
import prisma from "@/lib/prisma";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import Link from "next/link";
import Leaderboard from "@/components/leaderboard";
import { buttonVariants } from "@/components/ui/button";
import Image from "next/image";
import { ArrowCircleRight } from "@phosphor-icons/react/dist/ssr";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import AskidaForm from "@/components/askida-form";
import HediyeForm from "@/components/hediye-form";
import { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

export default function HomePagePuzzle() {
  const [products, setProducts] = useState<any[]>([]);
  const [leaderboard, setLeaderboard] = useState<any[]>([]);

  const [selectedValue, setSelectedValue] = useState<string>("all");
  const handleOnChange = (value: string) => {
    setSelectedValue(value);

    fetch(`/api/puzzle/get-leaderboard/${value}`, { method: "POST" })
      .then((response) => response.json())
      .then((data) => setLeaderboard(data));
  };

  useEffect(() => {
    fetch("/api/products/")
      .then((response) => response.json())
      .then((data) => setProducts(data));
  }, []);

  useEffect(() => {
    fetch("/api/puzzle/get-leaderboard/all", { method: "POST" })
      .then((response) => response.json())
      .then((data) => setLeaderboard(data));
  }, []);

  return (
    <div
      className="mb-8 flex flex-col  gap-8 lg:ml-8 lg:w-1/3 lg:flex-col"
      data-testid={"homepage-puzzle"}
    >
      <div className="rounded-md border border-gray-200 bg-white ">
        <h2 className="mt-2 border-b-4  border-primary pl-2 text-[18px] font-semibold text-primary lg:w-1/2 ">
          Bulmacalar
        </h2>
        <Link href="/bulmacalar">
          <Image
            src={"/images/chessboard-start.jpg"}
            alt={"Bulmacalar"}
            width={500}
            height={500}
            className="mx-auto mb-4 h-auto max-w-full"
          />
        </Link>
      </div>
      <div className="rounded-md border border-gray-200 bg-white ">
        <div className="flex w-full justify-between pr-2">
          <h2 className="mt-2 border-b-4  border-primary pl-2 text-[18px] font-semibold text-primary lg:w-1/2 ">
            Bulmaca Sıralama
          </h2>
          <Select
            onValueChange={handleOnChange}
            value={selectedValue}
            defaultValue={selectedValue}
          >
            <SelectTrigger className="w-[130px]">
              <SelectValue placeholder="Tümü" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="all">Tümü</SelectItem>
              <SelectItem value="positional">Pozisyonel</SelectItem>
              <SelectItem value="tactic">Taktik</SelectItem>
            </SelectContent>
          </Select>
        </div>
        <Leaderboard leaderboard={leaderboard} showHeader={false} />
      </div>
      <div className=" flex h-full flex-col justify-end">
        <div className=" flex flex-col rounded-lg border-2 border-solid border-violet-500 bg-white p-4">
          <Dialog>
            <DialogTrigger className="w-full">
              <div className="flex w-full">
                <div className="w-full text-xl font-bold text-indigo-700">
                  Premium Üyelik Hediye Et!
                </div>

                <ArrowCircleRight size={30} className="mr-1" color="#3818D9" />
              </div>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle className="w-full text-center">
                  Üyelik Hediye Edin!
                </DialogTitle>
                {/* <Image
                  src="/images/askida-modal.png"
                  alt=""
                  width={150}
                  height={150}
                  className="mx-auto"
                /> */}
                <DialogDescription className="w-full text-center">
                  Üyelik hediye ederek satranç tutkunu bir sevdiğini mutlu
                  edebilir veya &quot;askıda üyelik&quot; özelliğimiz ile
                  eğitime ihtiyacı olan bir satrançsevere yardım eli
                  uzatabilirsin!
                </DialogDescription>
              </DialogHeader>
              <Tabs defaultValue="account" className="w-full text-center">
                <TabsList>
                  <TabsTrigger value="account">Askıda Üyelik</TabsTrigger>
                  <TabsTrigger value="password">Kişiye Üyelik</TabsTrigger>
                </TabsList>
                <TabsContent value="account">
                  <AskidaForm products={products} />
                </TabsContent>
                <TabsContent value="password">
                  <HediyeForm products={products} />
                </TabsContent>
              </Tabs>
            </DialogContent>
          </Dialog>
        </div>
      </div>
    </div>
  );
}
